import { FC, useEffect, useState } from 'react';
import { GetStaticPropsResult } from 'next';
import { useRouter } from 'next/router';
import Link from 'next/link';
import {
  Button,
  Container,
  Heading as ChakraHeading,
  Table,
  TableContainer,
  Tbody,
  useDisclosure,
} from '@chakra-ui/react';
import { Testimonials } from '@/components/common';
import { UnsubscribeModal } from '@/components/common';
import { QuestionRowItem, QuestionsTableHead } from '@/components/pages/questions';
import Layout from '@/components/layout';
import { IS_SERVER, Routes } from '@/constants';
import { Heading, AboutSection } from '@/components/pages/home';
import { staticQuestions } from '@/constants/staticQuestions';
import { Question } from '@/types/pages/home/Question';
import { cacheReferralCode } from '@/utils';
import styles from '@/styles/pages/Home.module.scss';

interface HomepageProps {
  questions: Question[];
}

const SHOULD_SHOW_STATUS = false;

const Homepage: FC<HomepageProps> = ({ questions }) => {
  const { asPath } = useRouter();
  const {
    isOpen: isUnsubscribeModalOpen,
    onOpen: openUnsubscribeModal,
    onClose: closeUnsubscribeModal,
  } = useDisclosure();
  const [subscriptionEndingDate, setSubscriptionEndingDate] = useState<string | null>();

  useEffect(() => {
    cacheReferralCode(asPath);
    if (!IS_SERVER && localStorage.getItem('subscriptionEndingDate')) {
      setSubscriptionEndingDate(localStorage.getItem('subscriptionEndingDate'));
      localStorage.removeItem('subscriptionEndingDate');
      openUnsubscribeModal();
    }
  }, [asPath, openUnsubscribeModal]);

  return (
    <Layout className={styles.questionsPage}>
      <UnsubscribeModal
        isModalOpen={isUnsubscribeModalOpen}
        onClose={closeUnsubscribeModal}
        subscriptionEndsAt={subscriptionEndingDate}
      />
      <Container display="flex" flexDir="column" maxWidth="1000px" py="10px">
        <Heading />
        <TableContainer className={styles.table}>
          <Table variant="striped" colorScheme="tableOddRows">
            <QuestionsTableHead isFullTable={SHOULD_SHOW_STATUS} />
            <Tbody>
              {questions.map((question) => (
                <QuestionRowItem key={question.id} question={question} isFullTable={SHOULD_SHOW_STATUS} />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Button className={styles.buttonAfterTable} colorScheme="red">
          <Link href={Routes.Questions} passHref className={styles.link}>
            View All Questions
          </Link>
        </Button>
        <ChakraHeading as="h2" variant="main" className={styles.testimonialsTitle}>
          Testimonials
        </ChakraHeading>
        <Testimonials />
        <AboutSection className={styles.aboutSection} />
      </Container>
    </Layout>
  );
};

export default Homepage;

export function getStaticProps(): GetStaticPropsResult<HomepageProps> {
  return {
    props: { questions: staticQuestions },
  };
}
